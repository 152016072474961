import * as React from 'react';
import styled from 'styled-components';
import {colors} from "../common/styles";

const Button = styled.button`
  background-color: ${colors.ivy};
  color: white;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  padding: 12px 24px;
  transition: .15s ease;
  &:hover {
    background-color: ${colors.ivylight};
  }
`;
export default Button;


import * as React from 'react';
import styled from 'styled-components';
import PageContent from '../components/PageContent';
import Layout from '../components/Layout';
import {mq, colors, fonts} from "../common/styles";
import { config } from "../common/config";
import Button from '../components/Button';
import {StaticImage} from "gatsby-plugin-image";

const CenterImage = styled.div`
    display: flex;
    justify-content: center;
`

const StyledFormContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    ${mq.tablet} {
      flex-direction: row;
      justify-content: center;
    }
    .primer {
      ${mq.tablet} {
        margin-left: 32px;
      }
      ${mq.laptop} {
        margin-left: 64px;
      }
      p {
        text-align: center;
      }
    }
    form {   
      display: flex;
      flex-direction: column;
      align-items: center;
      ${mq.laptop} {
        align-items: unset;
      }
      p {
        display: flex;
        flex-direction: column;
        width: 100%;
        ${mq.tablet} {
          min-width: 400px;
        }
      }
    }

    label {
      display: block;
      color: ${colors.gunmetallight};
    }
    input, select, textarea {
      flex: 1;
      padding: 12px;
      margin: 4px 0;
      border: 1px solid ${colors.gunmetallight};
      box-sizing: content-box;
      border-radius: 2px;
      font-family: ${fonts.montserrat};
      font-size: 14px;
      &:focus {
        outline: ${colors.rust} auto 1px;
      }
      &::placeholder, &:required:invalid {
        color: ${colors.gunmetallight};
        font-weight: 300;
      }
    }
`;

const Required = styled.span`
    font-size: 10px;
    font-weight: 300;
    &::after {
        content: "*";
    }
`;

const messagePlaceholder = `*Deetz Pls*
Guest count
Budget
Event Date
Theme
Colors
Amount`;

export function Services() {
  return (
    <Layout subPageTitle='Contact'>
      <PageContent>
          <StyledFormContainer>
              <form
                name="contact"
                method="POST"
                data-netlify="true"
              >
                  <input type="hidden" name="form-name" value="contact" />
                  <p>
                      <label>Name <Required/></label>
                      <input type="text" name="name" placeholder='Your name' required/>
                  </p>
                  <p>
                      <label>Email <Required/></label>
                      <input type="email" name="email" placeholder='Your email address' required/>
                  </p>
                  <p>
                      <label>Service Type <Required /></label>
                      <select name="serviceType" required defaultValue="">
                          <option value="" disabled hidden>Which type of event are you planning?</option>
                          <option value="event">Event</option>
                          <option value="wedding">Wedding</option>
                          <option value="design">Design</option>
                          <option value="rentals">Rentals</option>
                      </select>
                  </p>
                  <p>
                      <label>Event Venue <Required /></label>
                      <select name="eventVenue" required defaultValue="">
                          <option value="" disabled hidden>Which type of venue?</option>
                          <option value="indoor">Indoor</option>
                          <option value="outdoor">Outdoor</option>
                      </select>
                  </p>
                  <p>
                      <label>Message <Required /></label>
                      <textarea
                          name="message"
                          placeholder={messagePlaceholder}
                          required
                          rows={7}
                      ></textarea>
                  </p>
                  <p>
                      <Button type="submit">Send</Button>
                  </p>
              </form>
              <div className='primer'>
                  <div>
                      <p>
                          Thank you for taking interest and reaching out! I look forward to helping you make wonderful memories. If you prefer, you can email me directly at <a href={`mailto:${config.links.email}`}>{config.links.email}</a> or DM me on <a href={config.links.instagram}>Instagram</a> or <a href={config.links.facebook}>Facebook</a>.
                      </p>
                  </div>
                  <div className='hide-mobile'>
                      <CenterImage>
                          <StaticImage src={'../images/logo-square.png'} width={420} placeholder='none' formats={['auto']} alt='Ivory and Rust Big Logo'/>
                      </CenterImage>
                  </div>
              </div>
          </StyledFormContainer>
      </PageContent>
      <div className='hide-laptop'>
        <CenterImage>
            <StaticImage src={'../images/logo-square.png'} width={420} height={420} placeholder='none' formats={['auto']} alt='Ivory and Rust Big Logo'/>
        </CenterImage>
      </div>
    </Layout>
  );
}

export default Services;
